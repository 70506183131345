import { withWindow } from '@shared/utils/withWindow';

interface BrowserLocaleArgs {
  languageCodeOnly?: boolean;
  fallbackLocale?: string;
}

/**
 * Returns an array of locales ordered by preference
 * @param languageCodeOnly for ex. will return ['es', 'en'] instead of ['es-MX', 'es', 'en-US', 'en']
 */
export const useBrowserLocale = ({ languageCodeOnly = false, fallbackLocale = 'en-US' }: BrowserLocaleArgs) => {
  const browserLocales = withWindow(
    globalWindow => {
      // navigator.language = UI Language (typically operatinng system lang)
      // will fallback to en-US in internet explorer
      const locale = globalWindow.navigator.language || fallbackLocale;

      // navigator.languages = user-set preferred languages in browser ordered by preference
      return globalWindow.navigator.languages === undefined ? [locale] : globalWindow.navigator.languages;
    },
    ['en-US']
  );

  const locales =
    browserLocales?.map(locale => {
      const trimmedLocale = locale.trim();
      // languageCodeOnly ie. en instead of en-CA etc.
      return languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
    }) || [];

  return {
    locales
  };
};
