import React, { useCallback, useEffect, useState } from 'react';
import { animated, AnimatedValue, useSpring } from 'react-spring';
import { DndContext, DraggableSyntheticListeners, useDraggable } from '@dnd-kit/core';
import { Coordinates, CSS } from '@dnd-kit/utilities';
import { createDisplayName } from '@withjoy/joykit/utils';
import { forwardRef } from '@shared/utils/forwardRef';

import { createContext } from '@shared/utils/createContext';
import { callAllHandlers } from '@shared/utils/functions';
import { Backdrop, BackdropProps } from '@withjoy/joykit/components/Backdrop';
import { StyledRoot, StyledHeader, StyledBody, StyledContentContainer, StyledContent, StyledFooter, DialogStylesProvider, modalStyles, useModalStyles } from './Modal.styles';
import type { ModalProps, ModalHeaderProps, ModalBodyProps, ModalFooterProps, ModalContextValue, ModalDefaultProps, ModelContentAdditionalProps } from './Modal.types';
import { mergeOverrides, useOverrides } from '@shared/utils/overrides';
import { CloseButton, CloseButtonProps } from '../CloseButton';
import { FocusLock } from '../FocusLock';
import { useStyleConfig } from '@shared/joykit/packages/core/common/utils/styleConfig';
import { RemoveScroll } from 'react-remove-scroll';

import { Portal } from '../Portal';

import { useModal } from './useModal';
import { Box } from '../Box';
import { animationTransitionExt } from '@shared/utils/animationTransition';

const [ModalProvider, useModalContext] = createContext<ModalContextValue>({ name: 'DialogContext' });

type ModalOverlay = React.FC<ModalProps> & {
  Header: typeof ModalHeader;
  Body: typeof ModalBody;
  Footer: typeof ModalFooter;
  CloseButton: typeof ModalCloseButton;
  Content: typeof ModalContent;
  Backdrop: typeof ModalBackdrop;
};

const DIALOG_CONTENT_ANIMATIONS = {
  in: { scale: 1, opacity: 1 },
  out: { scale: 0.985, opacity: 0 }
} as const;

const Modal: ModalOverlay = props => {
  const {
    appendToParentPortal,
    children,
    disableFocusLock,
    disableAutoFocus,
    enableReturnFocusOnClose,
    finalFocusRef,
    initialFocusRef,
    onClose,
    overrides,
    portalContainerRef
  } = props as React.PropsWithChildren<ModalProps & ModalDefaultProps>;

  const styles = useStyleConfig(modalStyles, props as ModalProps & ModalDefaultProps);

  const { handleOnBackdropFrame, shouldKeepOverlayOpen, ...modal } = useModal(props);
  const [{ x, y }, setCoordinates] = useState<Coordinates>({ x: 0, y: 0 });

  const ctx: ModalContextValue = {
    ...modal,
    coordinates: { x, y },
    setCoordinates,
    onClose,
    overrides,
    disableFocusLock,
    disableAutoFocus,
    enableReturnFocusOnClose,
    finalFocusRef,
    initialFocusRef
  };

  return (
    <ModalProvider value={ctx}>
      <DialogStylesProvider value={styles}>
        {(ctx.isOpen || shouldKeepOverlayOpen) && (
          <Portal appendToParentPortal={appendToParentPortal} containerRef={portalContainerRef}>
            <DndContext
              onDragEnd={({ delta }) => {
                setCoordinates(({ x, y }) => {
                  return {
                    x: x + delta.x,
                    y: y + delta.y
                  };
                });
              }}
            >
              {' '}
              {children}
            </DndContext>
          </Portal>
        )}
      </DialogStylesProvider>
    </ModalProvider>
  );
};

Modal.defaultProps = {
  appendToParentPortal: true,
  overrides: {},
  size: 'xl',
  addAriaLabels: true,
  stickyBehavior: 'header',
  scrollBehavior: 'outside'
};

const ModalBackdrop = forwardRef<'div', Omit<BackdropProps, 'isOpen'>>((props, ref) => {
  const { isOpen, setVisibilityForKey } = useModalContext();

  const handleOnBackdropFrame = useCallback(
    (styles: { opacity: number }) => {
      setVisibilityForKey('backdrop', styles.opacity > 0.02);
    },
    [setVisibilityForKey]
  );

  return <Backdrop {...props} pointerEvents={isOpen ? undefined : 'none'} ref={ref} isOpen={isOpen} onFrame={props.onFrame || handleOnBackdropFrame} />;
});

const DragHandle: React.FC<{
  listeners: DraggableSyntheticListeners;
  attributes: {
    role: string;
    tabIndex: number;
    'aria-pressed': boolean | undefined;
    'aria-roledescription': string;
    'aria-describedby': string;
  };
}> = ({ listeners, attributes }) => (
  <>
    <Box position="absolute" top={0} left={0} right={'48px'} height="48px" {...listeners} {...attributes} tabIndex={-1}></Box>
    <Box position="absolute" top={0} left={0} bottom={0} width="48px" {...listeners} {...attributes} tabIndex={-1}></Box>
    <Box position="absolute" left={0} bottom={0} right={0} height="48px" {...listeners} {...attributes} tabIndex={-1}></Box>
    <Box position="absolute" top={'48px'} bottom={0} right={0} width="48px" {...listeners} {...attributes} tabIndex={-1}></Box>
  </>
);

const ModalContent = forwardRef<'section', Pick<ModalProps, 'overrides' | 'children'> & ModelContentAdditionalProps>((props, ref) => {
  const { children, transition } = props;

  const { coordinates, setCoordinates, dialogId, isDraggable, getDialogRootProps, getDialogProps, isOpen, overrides, setVisibilityForKey } = useModalContext();

  useEffect(() => {
    return () => setCoordinates({ x: 0, y: 0 });
  }, []);

  const {
    Root: [Root, rootProps],
    ContentContainer: [ContentContainer, contentContainerProps],
    Content: [Content, contentProps]
  } = useOverrides({ Root: StyledRoot, ContentContainer: StyledContentContainer, Content: StyledContent }, overrides);

  const styles = useModalStyles();

  const animationsToBeApplied = transition || DIALOG_CONTENT_ANIMATIONS;

  const contentSprings = useSpring({
    from: animationsToBeApplied.out,
    to: isOpen ? animationsToBeApplied.in : animationsToBeApplied.out,
    config: {
      clamp: true,
      friction: 20,
      tension: 250
    },
    onFrame: (styles: { opacity: number }) => {
      setVisibilityForKey('content', styles.opacity > 0.015);
    }
  }) as AnimatedValue<{ opacity: number; scale: number }>;

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `${dialogId}_draggable`,
    disabled: !isDraggable
  });
  const dragStyle = {
    transform: CSS.Translate.toString(transform)
  };

  const scaleSpringValue = contentSprings.scale?.getValue();

  return (
    <ModalFocusLock>
      <Root __css={styles.dialogRoot} {...getDialogRootProps(rootProps)}>
        <ContentContainer __css={styles.contentContainer} {...contentContainerProps}>
          <Box
            ref={setNodeRef}
            top={coordinates.y}
            left={coordinates.x}
            width={'100%'}
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={dragStyle}
          >
            <Content
              __css={styles.content}
              as={animated.section}
              id={dialogId}
              // Setting perspective(1px) resolves the text blurriness that occurs when applying scale transformation 🤷🏼‍♂️
              style={{
                opacity: contentSprings.opacity,
                transform: typeof scaleSpringValue === 'number' ? `perspective(1px) translate3d(0px, 0px, 0) scale(${scaleSpringValue})` : undefined,
                transition: animationTransitionExt({ property: 'transform', duration: '300ms' })

                // 03/11/25: Temporarily disabling scale transformation because of an extremly slowed down
                //           animation transiton in Chrome, Version 134.0.6998.45
                // transform: contentSprings.scale?.interpolate(x => `perspective(1px) translate3d(0px, 0px, 0) scale(${x})`)
              }}
              {...getDialogProps(contentProps, ref)}
            >
              {isDraggable && <DragHandle listeners={listeners} attributes={attributes} />}
              {children}
            </Content>
          </Box>
        </ContentContainer>
      </Root>
    </ModalFocusLock>
  );
});

const ModalFocusLock: React.FC<{ children: React.ReactElement }> = props => {
  const { disableAutoFocus, disableFocusLock, enableReturnFocusOnClose, finalFocusRef, initialFocusRef } = useModalContext();
  return (
    <FocusLock
      enableAutoFocus={!disableAutoFocus}
      enableRestoreFocus={enableReturnFocusOnClose}
      isDisabled={disableFocusLock}
      finalFocusRef={finalFocusRef}
      initialFocusRef={initialFocusRef}
    >
      <RemoveScroll enabled={true} allowPinchZoom={false} removeScrollBar={true} forwardProps={true} noIsolation={true}>
        {props.children}
      </RemoveScroll>
    </FocusLock>
  );
};

////////////////////////////////////////////////////////////////////////

const ModalCloseButton = forwardRef<'button', Omit<CloseButtonProps, 'overrides'>>((props, ref) => {
  const { onClose } = useModalContext();
  const styles = useModalStyles();
  const { overrides } = useModalContext();

  return (
    <CloseButton
      overrides={mergeOverrides({ Root: { props: { ...styles.closeButton } } }, { Root: overrides.CloseButton })}
      {...props}
      ref={ref}
      onClick={callAllHandlers(props.onClick, ev => {
        ev.stopPropagation();
        onClose?.({ closeSource: 'closeButton', event: ev });
      })}
    />
  );
});

ModalCloseButton.defaultProps = {
  variant: 'ghost',
  shape: 'square'
};
////////////////////////////////////////////////////////////////////////

const ModalHeader = forwardRef<'div', ModalHeaderProps>((props, ref) => {
  const { headerId, setHeaderMounted, overrides } = useModalContext();
  const styles = useModalStyles();

  const {
    Header: [Header, headerProps]
  } = useOverrides({ Header: StyledHeader }, overrides);

  useEffect(() => {
    setHeaderMounted(true);
    return () => setHeaderMounted(false);
  }, [setHeaderMounted]);
  return <Header as="header" __css={styles.header} id={headerId} ref={ref} {...props} {...headerProps} />;
});

////////////////////////////////////////////////////////////////////////

const ModalBody = forwardRef<'div', ModalBodyProps>((props, ref) => {
  const { bodyId, setBodyMounted, overrides } = useModalContext();
  const styles = useModalStyles();

  const {
    Body: [Body, bodyProps]
  } = useOverrides({ Body: StyledBody }, overrides);

  useEffect(() => {
    setBodyMounted(true);
    return () => setBodyMounted(false);
  }, [setBodyMounted]);

  return <Body id={bodyId} ref={ref} __css={styles.body} {...props} {...bodyProps} />;
});

////////////////////////////////////////////////////////////////////////

const ModalFooter = forwardRef<'footer', ModalFooterProps>((props, ref) => {
  const { overrides } = useModalContext();
  const styles = useModalStyles();

  const {
    Footer: [Footer, footerProps]
  } = useOverrides({ Footer: StyledFooter }, overrides);

  return <Footer as="footer" ref={ref} __css={styles.footer} {...props} {...footerProps} />;
});

////////////////////////////////////////////////////////////////////////

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.CloseButton = ModalCloseButton;
Modal.Content = ModalContent;
Modal.Backdrop = ModalBackdrop;

Modal.displayName = createDisplayName('Modal');
Modal.Body.displayName = createDisplayName('ModalContent');
ModalHeader.displayName = createDisplayName('ModalHeader');
ModalBody.displayName = createDisplayName('ModalBody');
ModalFooter.displayName = createDisplayName('ModalFooter');
ModalCloseButton.displayName = createDisplayName('CloseButton');

export { Modal, ModalFocusLock, useModalContext, ModalHeader, ModalFooter, ModalBody, ModalContent, ModalCloseButton, ModalBackdrop };
