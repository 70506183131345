import { getOperationAST, OperationTypeNode } from 'graphql';
import { RetryLink } from '@apollo/client/link/retry';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import fetchMethod from 'isomorphic-fetch';
import possibleTypesResultData from '@graphql/generated';
import { BatchModeValue } from '@graphql/react-hooks';
import { getTerminatingLink as clientDiagnosticsTerminatingLink } from './clientDiagnostics';
import globalWindow from '@shared/core/globals';
import { LoginManager } from '../auth';
import { ApolloClient, ApolloLink, HttpLink, Observable, Operation, from, InMemoryCache, TypePolicies } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { catalogCachePolicies } from '@apps/registry/common/components/Catalog/Catalog.cache';
import { v4 as uuid } from 'uuid';
import AbortController from 'abort-controller';
import { IncomingHttpHeaders } from 'http';
import { logGQLTelemetry } from './logGQLTelemetry';
import { createEnforceWafCaptchaLink } from './enforceWafCaptchaLink';
import { isAsyncApolloAuthEnabled } from '.';
import { cardShopCachePolicies } from '@apps/card/routes/Dashboard/components/DesignsGallery/DesignsGallery.cache';

export interface ClientArgs {
  graphQLUrl: string;
  loginManager: LoginManager;

  overrides?: {
    cache?: InMemoryCache;
  };
  ssrAuthToken?: string;
  headers?: IncomingHttpHeaders;
}

export interface PersistentStorage<T> {
  getItem: (key: string) => Promise<T> | T;
  setItem: (key: string, data: T) => Promise<void> | void;
  removeItem: (key: string) => Promise<void> | void;
}

const networkErrorRegex = RegExp(/(network|fetch)/gi);

function shouldConsiderNetworkError(error: Maybe<Error>): boolean {
  return !!error && networkErrorRegex.test(error.message);
}

/**
 * At the root network layer, we only want to retry read + subscription operations.
 * The app layer has the control to retry based upon the context of the code.
 */
function shouldRetryOperation(operationType: Maybe<OperationTypeNode>): boolean {
  return operationType === 'query' || operationType === 'subscription';
}

function understandOperation({ query, operationName }: Operation): { shouldRetry: boolean } {
  // When a request is made, GraphQL combines the query document with the schema definition (schema.graphql)
  // and converts it into a tree structure.
  //
  // A query document can contain multiple `OperationDefinition` - one of many node types in the GraphQL AST.
  //
  // There are 3 types of executable operations: query, mutation, and subscription.

  // Find the appropriate operation.
  const match = getOperationAST(query, operationName);

  return { shouldRetry: shouldRetryOperation(match?.operation) };
}

const getTerminatingLink = (url: string, fetch: typeof fetchMethod) => {
  const fastBatchLink = new BatchHttpLink({
    batchInterval: 200,
    credentials: 'include',
    uri: url,
    fetch
  });

  const slowBatchLink = new BatchHttpLink({
    batchInterval: 10000,
    credentials: 'include',
    uri: url,
    fetch
  });

  const httpLink = new HttpLink({
    credentials: 'include',
    uri: url,
    fetch
  });

  const requestHandlerLinkMap = {
    fast: fastBatchLink,
    off: httpLink,
    slow: slowBatchLink
  } as Readonly<Record<BatchModeValue, ApolloLink>>;

  return new ApolloLink(operation => {
    const batchStateFromCode: BatchModeValue = operation.variables?.batchMode;
    const link = requestHandlerLinkMap[batchStateFromCode] || httpLink;
    return link.request(operation) || Observable.of();
  });
};

const adminCachePolicies: TypePolicies = {
  // Enforce merging this non-normalized object types
  RecommendEvent: {
    merge: true
  },
  Date: {
    merge: true
  },
  RegistryFulfillment: {
    merge: true
  },
  RegistryOrderLineItemData: {
    merge: true
  },
  MonetaryValue: {
    merge: true
  },
  Currency: {
    merge: true
  },
  TransferredRegistryData: {
    merge: true
  },
  LineItem: {
    keyFields: ['locator']
  },
  RegistryItemProductData: {
    keyFields: ['registryItem', ['id']]
  },
  RegistryItemCount: {
    merge: true
  },
  ShippingAddress: {
    merge: true
  },
  ...(__DEV__ && {
    // Enforce id is added to this types so cache can merge them accordingly
    // if keyFields is something other than ['id'] then it should be moved above
    Event: {
      keyFields: ['id']
    },
    EventPage: {
      keyFields: ['id']
    },
    EventInfo: {
      keyFields: ['id']
    }
  })
};

const guestCachePolicies: TypePolicies = {
  EventSession: {
    merge: true
  }
};

const createAuthLink = ({ loginManager, ssrAuthToken, headers = {} }: ClientArgs): ApolloLink => {
  const abortController = new AbortController();
  const createAuthContext = (accessToken: string | null) => {
    let token = accessToken;
    if (!token && ssrAuthToken) {
      token = ssrAuthToken;
    }

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'x-joy-request-id': uuid()
      },
      // to prevent this issue: https://github.com/apollographql/apollo-client/issues/6769
      fetchOptions: {
        signal: abortController.signal
      }
    };
  };
  const asyncAuthLink = setContext(async () => {
    const token = await loginManager.getTokenAsync();
    return createAuthContext(token);
  });

  const authLink = new ApolloLink((operation, forward) => {
    const token = loginManager.getToken();
    operation.setContext(createAuthContext(token));
    return forward?.(operation) ?? null;
  });

  return new ApolloLink((operation, forward) => {
    const link = isAsyncApolloAuthEnabled() ? asyncAuthLink : authLink;
    return link.request(operation, forward);
  });
};

export const createApolloCache = () => {
  const typePolicies = {
    ...catalogCachePolicies,
    ...adminCachePolicies,
    ...guestCachePolicies,
    ...cardShopCachePolicies,
    Query: {
      fields: {
        stationeryTemplatesContainer: {
          keyArgs: ['filter', 'includeOptions', 'first']
        },
        // Ensure unique cache entry by including the `query` as part of the generated key
        productCatalogSearchResults: {
          keyArgs: ['query']
        },
        // Ensure unique cache entry by including the `eventId` as part of the generated key
        recommendCatalogHomeSections: {
          keyArgs: ['eventId', 'storefront']
        },
        // Ensure eventById will always return a reference to the event
        eventById(_, { args, toReference }) {
          return toReference({
            __typename: 'Event',
            __ref: args?.id
          });
        }
      }
    }
  } as TypePolicies;

  return new InMemoryCache({
    possibleTypes: possibleTypesResultData.possibleTypes,
    typePolicies
  }).restore(globalWindow.__INITIAL_APOLLO_STATE__ || {});
};

export const createApolloClient = (args: ClientArgs) => {
  const { graphQLUrl, overrides } = args;
  const cache = overrides?.cache || createApolloCache();
  const authLink = createAuthLink(args);
  const errorLoggingLink = onError(({ networkError, graphQLErrors, operation }: ErrorResponse) => {
    logGQLTelemetry({
      name: operation.operationName,
      startTimeMs: operation.getContext().startTime,
      status: networkError ? 'NetworkError' : graphQLErrors ? 'GraphQLError' : 'UnknownError',
      errors: [...(networkError ? [networkError] : []), ...(graphQLErrors || [])]
    });
  });

  const successLoggingLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      startTime: performance.now()
    });

    return forward(operation).map(data => {
      logGQLTelemetry({
        name: operation.operationName,
        startTimeMs: operation.getContext().startTime,
        status: 'Success'
      });
      return data;
    });
  });

  // The retry link does not handle retries for GraphQL errors in the response, only for network errors.
  // This means that the entire query was rejected, and therefore no data was returned.
  const retryLink = new RetryLink({
    delay: {
      // The number of milliseconds to wait before attempting the first retry
      initial: 300,
      // The maximum number of milliseconds that the link should wait for any retry
      // @default Infinity
      max: 2000,
      // Whether delays between attempts should be randomized.
      // Combined with initial, this will help alleviate the thundering herd problem.
      jitter: true
    },

    attempts: {
      max: 5,
      retryIf: (error, operation) => {
        if (shouldConsiderNetworkError(error)) {
          return understandOperation(operation).shouldRetry;
        }
        return false;
      }
    }
  });

  const terminatingLink = globalWindow.location?.search.includes('feature.')
    ? clientDiagnosticsTerminatingLink(graphQLUrl, getTerminatingLink)
    : getTerminatingLink(graphQLUrl, fetchMethod);

  return new ApolloClient({
    connectToDevTools: __BROWSER__,
    cache,
    // Ordering of links matter.
    // - authLink goes before retry + error links so that auth headers are set prior to any HTTP requests.
    // - retryLink should be before HTTP link so that it can retry specific operations before logging it as an error.
    link: from([authLink, successLoggingLink, errorLoggingLink, retryLink, createEnforceWafCaptchaLink(graphQLUrl), terminatingLink]),
    queryDeduplication: true,
    ssrMode: true,
    name: 'joy-web',
    version: process.env.APOLLO_CLIENT_VERSION // comes from webpack.DefinePlugin
  });
};
