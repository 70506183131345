/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Check Filled',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM11.3622 6.91392C11.5908 6.71389 11.6139 6.36642 11.4139 6.13782C11.2139 5.90922 10.8664 5.88606 10.6378 6.08608L7.02506 9.24725L5.38891 7.61109C5.17412 7.3963 4.82588 7.3963 4.61109 7.61109C4.3963 7.82588 4.3963 8.17412 4.61109 8.38891L6.61109 10.3889C6.81584 10.5937 7.14427 10.6046 7.36218 10.4139L11.3622 6.91392Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <rect width="24" height="24" rx="12" fill="currentColor" />
          <path d="M6 12.45L10.32 16.5L18 7.5" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42 24C42 33.9411 33.9411 42 24 42C14.0589 42 6 33.9411 6 24C6 14.0589 14.0589 6 24 6C33.9411 6 42 14.0589 42 24ZM33.4939 20.0644C33.8056 19.7917 33.8372 19.3178 33.5644 19.0061C33.2917 18.6944 32.8178 18.6628 32.5061 18.9356L21.0342 28.9735L15.5303 23.4697C15.2374 23.1768 14.7626 23.1768 14.4697 23.4697C14.1768 23.7626 14.1768 24.2374 14.4697 24.5303L20.4697 30.5303C20.7489 30.8095 21.1967 30.8244 21.4939 30.5644L33.4939 20.0644Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
