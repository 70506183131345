import { initIntercom } from './init';
import { IdentifyProp } from '../analytics/context';
import globalWindow from '../globals';
import { config } from '@static/js/joy';

export class IntercomService {
  public appId?: string;

  constructor(appId?: string) {
    this.appId = appId;

    globalWindow.intercomSettings = {
      app_id: appId,
      hide_default_launcher: true
    };
  }

  public identify = (idProps: IdentifyProp) => {
    globalWindow.intercomSettings = {
      app_id: this.appId,
      user_id: idProps.auth0Id,
      userId: idProps.userId,
      eventId: idProps.eventId,
      name: idProps.name,
      email: idProps.email
    } as Intercom_.IntercomSettings;

    if (globalWindow.Intercom && window.Intercom?.booted) {
      window.Intercom('update');
    }
  };

  public show = () => {
    if (globalWindow.Intercom) {
      globalWindow.Intercom('show');
    }
  };

  public hide = () => {
    if (globalWindow.Intercom) {
      globalWindow.Intercom('hide');
    }
  };

  public boot = async () => {
    if (!globalWindow.Intercom) {
      // Must ensure intercom has be initialized, otherwise `boot` call won't work.
      await this.init();
    }

    if (globalWindow.Intercom && window.Intercom?.booted) {
      // Initializes the messenger for the current user.
      globalWindow.Intercom('boot', { app_id: this.appId });
    }
  };

  /**
   * Will clear the cookie used to track the user's conversations.
   */
  public shutdown = () => {
    if (globalWindow.Intercom) {
      globalWindow.Intercom('shutdown');
    }
  };

  public init = async () => {
    if (this.appId) {
      await initIntercom(this.appId);
    }
  };

  public hideLauncher = () => {
    if (globalWindow.Intercom) {
      globalWindow.Intercom('update', { hide_default_launcher: true });
    }
  };

  public showLauncher = async () => {
    if (!globalWindow.Intercom) {
      // Must request Intercom module + boot it for the current user.
      await this.boot();
    }

    if (globalWindow.Intercom) {
      // Boot intercom launcher if not booted before show launcher.
      if (!globalWindow.Intercom?.booted) {
        globalWindow.Intercom('boot', { app_id: this.appId });
      }
      globalWindow.Intercom('update', { hide_default_launcher: false });
    }
  };

  public startSurvey = (id: string) => {
    if (globalWindow.Intercom) {
      globalWindow.Intercom('startSurvey', id);
    }
  };
}

export const intercomService = new IntercomService(config.intercomAppId);
