import { TypePolicies } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

export const cardShopCachePolicies: TypePolicies = {
  StationeryTemplateContainer: {
    fields: {
      pagedResults: relayStylePagination()
    }
  },

  // StationeryTag doesn't have an `id` field, so we'll
  // use the unique `name` field as the key.
  StationeryTag: {
    keyFields: ['name']
  }
};
