import { useCallback, useMemo } from 'react';
import { TOptions, StringMap, TFunction } from 'i18next';
import { useTranslation as useTranslationPackage, UseTranslationOptions } from 'react-i18next';
import { get } from 'lodash-es';

import { getNodeEnv } from '@shared/utils/getEnvironmentVariables';
import type { TranslationNamespaces, TranslateFunction, TranslationsByNamespace } from '@locales/generated';
import { pseudoLocalizeString } from '@shared/core/i18n/pseudoLocalization/pseudoLocalizeString';
import { DEFAULT_LANGUAGE } from '@shared/core/i18n/utils';
import { useFeatureValue } from '../featureFlags';
// prettier-ignore
export interface GetTranslation<T> {
  <P1 extends keyof T>(prop1: P1): T[P1];
  <P1 extends keyof T, P2 extends keyof T[P1]>(prop1: P1, prop2: P2): T[P1][P2];
  <P1 extends keyof T, P2 extends keyof T[P1], P3 extends keyof T[P1][P2]>(prop1: P1, prop2: P2, prop3: P3): T[P1][P2][P3];
  <P1 extends keyof T, P2 extends keyof T[P1], P3 extends keyof T[P1][P2], P4 extends keyof T[P1][P2][P3]>(prop1: P1, prop2: P2, prop3: P3, prop4: P4):T[P1][P2][P3][P4];
  <P1 extends keyof T, P2 extends keyof T[P1], P3 extends keyof T[P1][P2], P4 extends keyof T[P1][P2][P3], P5 extends keyof T[P1][P2][P3][P4]>( prop1: P1, prop2: P2, prop3: P3, prop4: P4, prop5: P5 ): T[P1][P2][P3][P4][P5];
  <P1 extends keyof T, P2 extends keyof T[P1], P3 extends keyof T[P1][P2], P4 extends keyof T[P1][P2][P3], P5 extends keyof T[P1][P2][P3][P4], P6 extends keyof T[P1][P2][P3][P4][P5]>( prop1: P1, prop2: P2, prop3: P3, prop4: P4, prop5: P5, prop6: P6 ): T[P1][P2][P3][P4][P5][P6];
  <P1 extends keyof T, P2 extends keyof T[P1], P3 extends keyof T[P1][P2], P4 extends keyof T[P1][P2][P3], P5 extends keyof T[P1][P2][P3][P4], P6 extends keyof T[P1][P2][P3][P4][P5], P7 extends keyof T[P1][P2][P3][P4][P5][P6]>(prop1: P1, prop2: P2, prop3: P3, prop4: P4, prop5: P5, prop6: P6, prop7: P7): T[P1][P2][P3][P4][P5][P6][P7];
  <P1 extends keyof T, P2 extends keyof T[P1], P3 extends keyof T[P1][P2], P4 extends keyof T[P1][P2][P3], P5 extends keyof T[P1][P2][P3][P4], P6 extends keyof T[P1][P2][P3][P4][P5], P7 extends keyof T[P1][P2][P3][P4][P5][P6], P8 extends keyof T[P1][P2][P3][P4][P5][P6][P7]>(prop1: P1, prop2: P2, prop3: P3, prop4: P4, prop5: P5, prop6: P6, prop7: P7, prop8: P8): T[P1][P2][P3][P4][P5][P6][P7][P8];
}

type LeafNodeValue = string | TranslateFunction;

type TransformedValue<T extends LeafNodeValue> = T | MappedTranslationObject<T> | (T | MappedTranslationObject<T>)[];
type MappedTranslationObject<T extends LeafNodeValue> = {
  [key: string]: TransformedValue<T>;
};
type PathComponent = string | number;

type LeafNodeTransformer<T> = (fullPath: PathComponent[]) => T;

type Handlers<T> = {
  stringTransformer: LeafNodeTransformer<T>;
  functionTransformer: LeafNodeTransformer<T>;
  unsupportedTransformer: LeafNodeTransformer<T>;
};

type UseTranslationValue<N extends TranslationNamespaces> = {
  t: GetTranslation<TranslationsByNamespace[N]>;

  /**
   * @deprecated Use `t` instead.
   */
  t2: GetTranslation<TranslationsByNamespace<string>[N]>;
};

const nodeEnv = getNodeEnv();

const defaultStringResolve = (context: 'path' | 'branch' | 'transform', path: PathComponent[], options?: TOptions, defaultValue: string = '') => {
  if (nodeEnv.isDevelopment || nodeEnv.isTest) {
    throw new Error(`NOOP leaf transformer. Context: ${context}, full path: ${stringifyInput(path)}, options: ${stringifyInput(options)}`);
  }

  return defaultValue;
};

const prefixKeyWithNamespace = (namespace: string, keys: PathComponent[]) => `${namespace}:${keys.join('.')}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stringifyInput = (input: any) => JSON.stringify(input || {}, null, 2);

const createNoOpTransformer = (context: 'path' | 'branch' | 'transform'): LeafNodeTransformer<TranslateFunction> => fullPath => options => {
  if (nodeEnv.isDevelopment || nodeEnv.isTest) {
    throw new Error(`NOOP leaf transformer. Context: ${context}, full path: ${stringifyInput(fullPath)}, options: ${stringifyInput(options)}`);
  }
  return defaultStringResolve(context, fullPath, options);
};

const push = (paths: PathComponent[], ...pathComponents: PathComponent[]) => {
  const clone = paths.slice();
  clone.push(...pathComponents);
  return clone;
};

const resolvePathComponents = (pathComponents: PathComponent[]) => {
  return pathComponents[pathComponents.length - 1];
};

const shouldImmediatelyTranslate = (fullPath: PathComponent[]) => {
  const key = resolvePathComponents(fullPath);
  return typeof key === 'string' && key.startsWith('_');
};

const transformValue = <T extends LeafNodeValue>(input: StringMap, key: string, fullPath: PathComponent[], handlers: Handlers<T>): TransformedValue<T> => {
  const value = get(input, key);

  if (typeof value === 'string') {
    // A string value is one base case and should be relegated to the handler
    return handlers.stringTransformer(fullPath);
  } else if (typeof value === 'function') {
    // A function value is another base case and should be relegated to the handler
    return handlers.functionTransformer(fullPath);
  } else if (Array.isArray(value)) {
    // Iterate over each item in the array
    return value.map((item: StringMap, index) => transformValue({ dummy: item }, 'dummy', push(fullPath, index), handlers)) as TransformedValue<T>;
  } else if (typeof value === 'object') {
    // Iterate over the object keys
    const nestedObject = Object.keys(value).reduce((nestedAcc, nestedKey) => {
      nestedAcc[nestedKey] = transformValue<T>(value, nestedKey, push(fullPath, nestedKey), handlers);
      return nestedAcc;
    }, {} as MappedTranslationObject<T>);
    return nestedObject;
  } else {
    return handlers.unsupportedTransformer(fullPath);
  }
};

const createTranslationObject = <T extends LeafNodeValue>(input: StringMap, handlers: Handlers<T>) => {
  return Object.keys(input).reduce((acc, key) => {
    acc[key] = transformValue(input, key, [key], handlers);
    return acc;
  }, {} as MappedTranslationObject<T>);
};

export function useTranslation<N extends TranslationNamespaces>(namespace: N, options?: UseTranslationOptions): UseTranslationValue<N> {
  const [origTFunction, i18n] = useTranslationPackage(namespace, options);
  const currentLanguage = i18n.language;

  // The generated locale types (`src/locales/generated.ts) is derived from the English (en-us) translation files.
  // Use the en-us bundle to create a translation tree that matches the typings.
  // NOTE: This ensures proper traversal for the returned translation functions and will not affect the actual localized string.
  const englishBundle = i18n.getResourceBundle(DEFAULT_LANGUAGE, namespace);
  const pseudoLocalization = useFeatureValue('pseudoLocalization');
  const shouldPseudoLocalize = currentLanguage === DEFAULT_LANGUAGE && pseudoLocalization;

  const t: TFunction = useCallback<TFunction>(
    (key: string | string[], options?: TOptions | string) => {
      // Pseudo-localize English strings in dev
      if (shouldPseudoLocalize) {
        return pseudoLocalizeString(origTFunction(key, options));
      }
      return origTFunction(key, options);
    },
    [origTFunction, shouldPseudoLocalize]
  );

  const translationObject = useMemo<MappedTranslationObject<TranslateFunction | string>>(() => {
    return createTranslationObject(englishBundle || {}, {
      stringTransformer: fullPath => {
        if (shouldImmediatelyTranslate(fullPath)) {
          return t(prefixKeyWithNamespace(namespace, fullPath));
        }

        return options => {
          return t(prefixKeyWithNamespace(namespace, fullPath), options);
        };
      },
      functionTransformer: createNoOpTransformer('path'),
      unsupportedTransformer: fullPath => {
        return options => defaultStringResolve('transform', fullPath, options);
      }
    });
  }, [englishBundle, namespace, t]);

  const getTranslationByPath = useCallback<GetTranslation<TranslationsByNamespace[N]>>(
    (...keys: string[]) => {
      return get(translationObject, keys);
    },
    [translationObject]
  );

  // TODO: Accept nested options arg and apply to matching branch key
  const getTranslatedBranch = useCallback<GetTranslation<TranslationsByNamespace<string>[N]>>(
    (...keys: string[]) => {
      const tFunctionOrBranch = get(translationObject, keys);
      // The value of a top level key is the translation function, not a branch.
      return typeof tFunctionOrBranch === 'function'
        ? tFunctionOrBranch()
        : createTranslationObject<string>(tFunctionOrBranch, {
            stringTransformer: fullPath => {
              return t(prefixKeyWithNamespace(namespace, push(keys, ...fullPath)));
            },
            functionTransformer: fullPath => {
              return t(prefixKeyWithNamespace(namespace, push(keys, ...fullPath)));
            },
            unsupportedTransformer: fullPath => {
              return defaultStringResolve('branch', fullPath);
            }
          });
    },
    [translationObject, namespace, t]
  );

  return {
    t: getTranslationByPath,
    t2: getTranslatedBranch
  };
}
