import { withWindow } from '@shared/utils/withWindow';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from '@react-router';
import { RoutesContext } from './RoutesContext';

export type RoutesPaths<T extends string> = Record<
  T,
  {
    path: string;
    goToPath: (...args: string[]) => string;
  }
>;

interface Props
  extends Readonly<{
    handle?: string;
    appUrl?: string;
    isGuestRoute?: boolean;
  }> {}

/**
 * RoutesProvider - Provides a routes context for helping to navigate around the app and define routes
 * @props handle: string -- the handle of the event if there is one
 * @props appUrl: string -- the app url of the event if there is one (e.g. '/:handle/edit/:appUrl' or '/:handle/:appUrl' depending on isGuestRoute)
 * @props isGuestRoute: boolean -- whether the route is a guest route or not (this affects the behavior of appUrl and therefore the buildPath function)
 * @props children: React.ReactNode -- the child components that will be wrapped by the RoutesContext.Provider
 * @returns wrapper component that provides a routes context for navigating around the app
 */
export const RoutesProvider: React.FC<Props> = ({ isGuestRoute, handle, appUrl, children }) => {
  const history = useHistory();
  const location = useLocation();
  const buildPath = useCallback(
    (route: string) => {
      const routePath = route ? (route.startsWith('/') ? route : `/${route}`) : '';
      if (isGuestRoute) {
        return `/${handle}/${appUrl}${routePath}`;
      } else if (handle) {
        return appUrl ? `/${handle}/edit/${appUrl}${routePath}` : `/${handle}/edit${routePath}`;
      } else {
        return appUrl ? `/${appUrl}${routePath}` : routePath;
      }
    },
    [appUrl, handle, isGuestRoute]
  );
  const setScrollToTop = () =>
    withWindow(window => {
      window.scrollTo(0, 0);
    });

  const goToAdmin = () => {
    history.push({
      pathname: handle ? `/${handle}/edit` : '/'
    });
  };

  const goToMe = () => {
    history.push({
      pathname: '/account/dashboard/my-events'
    });
  };

  const goToAccountMeRoute = (route: string) => {
    history.push({
      pathname: `/account/${route}`
    });
  };

  const goToAdminRoute = (route: string) => {
    history.push({
      pathname: handle ? buildPath(route) : '/'
    });
  };

  const goToExactRoute = (route: string, queryParams?: string) => {
    history.push({
      pathname: route,
      search: queryParams ?? undefined
    });
  };

  const isAdminRoute = !!handle && !isGuestRoute && location.pathname.startsWith(`/${handle}/edit`);

  return (
    <RoutesContext.Provider
      value={{
        isAdminRoute: isAdminRoute,
        isGuestRoute: isGuestRoute ?? (!isAdminRoute && location.pathname.startsWith(`/${handle}`)),
        location,
        buildPath,
        goToAdminRoute,
        goToExactRoute,
        goToAccountMeRoute,
        goToAdmin,
        goToMe,
        setScrollToTop
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
};

export const withRoutesProvider = (options: Props, component: React.ReactNode) => {
  return <RoutesProvider {...options}>{component}</RoutesProvider>;
};

export function createRoutePaths<
  Route extends string,
  Input extends Record<
    Route,
    {
      path: string;
      // Args is typed as 'any' to let the TSC infer
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      goToPath: (...args: any[]) => string;
    }
  >
>(input: Input) {
  return input as DeepReadonly<Input>;
}
