import { getConfig } from '@config';
import globalWindow from '@shared/core/globals';
const { googleMapsApiKey } = getConfig();

// Retry until google is available
const _checkGoogleLoaded = (cb: (googleMap: typeof google.maps) => void) => {
  if (globalWindow.google) {
    cb(globalWindow.google.maps);
  } else {
    setTimeout(() => {
      _checkGoogleLoaded(cb);
    }, 50);
  }
};

const _ensureGoogleMapsIsDefined = (cb: (googleMap: typeof google.maps) => void) => {
  const invoked = Array.from(document.head.getElementsByTagName('script')).find(script => script.src.includes('https://maps.googleapis.com/maps/api/js'));
  if (invoked) {
    _checkGoogleLoaded(cb);
    return;
  }

  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places,geometry`;
  script.async = true;
  script.onerror = () => {
    console.error('Error loading Google Maps API');
  };
  script.onload = () => _checkGoogleLoaded(cb);
  document.head.appendChild(script);
};

export const withGoogleMaps = (cb: (googleMap: typeof google.maps) => void) => {
  if (!globalWindow.google?.maps) {
    _ensureGoogleMapsIsDefined(cb);
  } else {
    cb(globalWindow.google.maps);
  }
};

export const initGoogleMaps = () => {
  withGoogleMaps(googleMaps => {});
};
