import { createShouldForwardProp } from '@styled-system/should-forward-prop';

import { flexbox, layout, position, background, ConfigStyle, typography, border, grid, boxShadow, zIndex } from 'styled-system';
import { spaceSystem, getStyledColor, borderSidesRadiusSystem } from '../../common/styledSystem/styledFunctions';

import { INVALID_PROPS_AND_STYLED_PROPS } from '../../utils/removeNonHTMLProps';
import type { ExtraSystemConfig } from '../../common/styledSystem/types';
import { filterUnique } from '../../utils/array';
import { marketingFontVariant } from '@shared/utils/fonts/marketingFonts';
import type { Theme as ThemeV1 } from '@withjoy/joykit';
import type { BoxProps } from './Box.types';
import { pseudoSelectors } from '../../common/pseudos';
import { notNullOrUndefined } from '@shared/utils/notNullOrUndefined';
import deepmerge from 'deepmerge';
import { system, compose, variant } from '../../common/styledSystem/core';

const extraStyleDefinitions: Readonly<Record<keyof ExtraSystemConfig, ConfigStyle | boolean>> = {
  appearance: true,
  aspectRatio: true,
  backdropFilter: true,
  backfaceVisibility: true,
  boxSizing: true,
  clipPath: true,
  content: true,
  cursor: true,
  fill: true,
  filter: true,
  gridColumnStart: true,
  gridColumnEnd: true,
  listStyle: true,
  mixBlendMode: true,
  objectFit: true,
  objectPosition: true,
  outline: true,
  pointerEvents: true,
  stroke: true,
  textDecoration: true,
  textDecorationColor: true,
  textDecorationThickness: true,
  textDecorationStyle: true,
  textDecorationLine: true,
  textOverflow: true,
  textTransform: true,
  touchAction: true,
  transition: true,
  transform: true,
  transformOrigin: true,
  transitionDuration: true,
  transitionDelay: true,
  transitionProperty: true,
  transitionTimingFunction: true,
  userSelect: true,
  visibility: true,
  whiteSpace: true,
  willChange: true,
  wordBreak: true
};

const extraStyleConfig = system(extraStyleDefinitions);

const styleConfigsWithScales = system({
  rowGap: {
    property: 'rowGap',
    scale: 'space'
  },
  gap: {
    property: 'gap',
    scale: 'space'
  },
  columnGap: {
    property: 'columnGap',
    scale: 'space'
  },
  inset: {
    property: 'inset',
    scale: 'space'
  }
});

const joykitV1TypeVariant = variant({
  prop: 'typeVariant',
  scale: 'typography.variants'
});

const joykitV2TypeVariant = variant({
  prop: 'typographyVariant',
  scale: 'typography.variants'
});

// Cannot add `styleFn`s (created via `system` function) to compose fn -- it will use style-system fallback breakpoints + other theme scales
// https://github.com/styled-system/styled-system/issues/2078
const systemProps = compose(
  spaceSystem,
  grid,
  getStyledColor,
  flexbox,
  layout,
  position,
  background,
  typography,
  joykitV2TypeVariant,
  joykitV1TypeVariant,
  marketingFontVariant,
  border,
  borderSidesRadiusSystem,
  boxShadow,
  zIndex,
  extraStyleConfig,
  styleConfigsWithScales
);

const propsToNotForward = filterUnique([...INVALID_PROPS_AND_STYLED_PROPS, 'variant', 'typeVariant', 'typographyVariant', ...(systemProps.propNames || [])]).sort();

export const shouldForwardProp = createShouldForwardProp(propsToNotForward);

const SYSTEM_PROP_SET = new Set([...(systemProps.propNames || [])] as string[]);

const isStyleProp = (prop: string) => SYSTEM_PROP_SET.has(prop) || prop in pseudoSelectors;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dict<T = any> = Record<string, T>;

const _objectFilter = <T extends Dict>(object: T, fn: (value: unknown, key: keyof Dict, object: T) => boolean) => {
  const result: Dict = {};

  Object.keys(object).forEach(key => {
    const value = object[key];
    const shouldPass = fn(value, key, object);
    if (shouldPass) {
      result[key] = value;
    }
  });

  return result;
};

const filterProps = (props = {}) => {
  return _objectFilter(props, (value, key) => notNullOrUndefined(value) && isStyleProp(key));
};

function styleSystemPropsParser<Theme extends ThemeV1>(props: BoxProps & { theme: Theme }) {
  const { theme, __css = {}, ...restProps } = props;

  const styles = deepmerge(__css, filterProps(restProps), {
    arrayMerge: (target, source) => source
  });

  return systemProps({ theme, ...styles });
}

export { styleSystemPropsParser };
